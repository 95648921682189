<template>
  <div
    class="wrapper"
    ref="wrapper"
  >
    <v-select
      :items="currencies"
      item-text="code"
      item-value="code"
      :label="$t('t.Currency')"
      v-model="currencyCpt"
      :disabled="disabled"
      :attach="$refs.wrapper"
      :menu-props="computedMenuProps"
    >
      <template v-slot:item="{item}">
        {{item.code}} {{item.isMain ? `(${$t('t.GlobalCurrency')})`:''}}
      </template>
      <template v-slot:selection="{item}">
        {{item.code}} {{item.isMain ? `(${$t('t.GlobalCurrency')})`:''}}
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currencies: [],
      isCurrenciesLoaded: false,
      isOutOfView: false,
      observer: new ResizeObserver(this.eventHandler)
    }
  },
  async created () {
    if (this.accountId) {
      const currenciesByAccount = (await this.$http().get('/core/accounts/' + this.accountId + '/currencies')).data
      this.currencies = []
      currenciesByAccount.forEach(currency => {
        this.currencies.push({ code: currency })
      })
    } else {
      const applicationSettingsRequest = await this.$http().get('/core/v6/settings/currencies')
      this.currencies = applicationSettingsRequest.data.currencies
    }

    this.isCurrenciesLoaded = true

    if (!this.currency || !this.currencies.map(c => c.code).includes(this.currency)) {
      this.currencyCpt = this.currencies[0].code
    }
  },
  mounted () {
    this.observe()
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
  methods: {
    eventHandler (entries) {
      const rect = entries[entries.length - 1].contentRect
      this.isOutOfView = (document.body.getBoundingClientRect().bottom - document.querySelector('footer').offsetHeight) < (this.$refs.wrapper.getBoundingClientRect().bottom + rect.bottom)
    },
    observe () {
      const element = this.$el.querySelector('div.v-menu__content')
      if (element) {
        this.observer.observe(element)
      } else {
        setTimeout(() => {
          this.observe()
        })
      }
    }
  },
  computed: {
    computedMenuProps () {
      return { top: this.isOutOfView, eager: true }
    },
    currencyCpt: {
      get () {
        return this.currency
      },
      set (val) {
        if (this.isCurrenciesLoaded) {
          this.$emit('update:currency', val)
        }
      }
    }
  },
  props: {
    currency: String,
    disabled: Boolean,
    accountId: String
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position relative
</style>
